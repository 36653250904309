/* AdminCupones.css */
.admin-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  h1 {
    color: #6a1b9a;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  form input[type="text"],
  form input[type="email"],
  form select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  form label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  form label input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .checkbox-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .form-buttons {
    display: flex;
    gap: 10px;
  }
  
  form button {
    background-color: #6a1b9a;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  form button:hover {
    background-color: #8e24aa;
  }
  
  form button[type="button"] {
    background-color: #f44336; /* Botón de cancelar en rojo */
  }
  
  form button[type="button"]:hover {
    background-color: #e53935;
  }
  
  .cupon-preview {
    max-width: 600px;
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .cupon-preview img {
    max-width: 100%;
    border-radius: 8px;
  }
  
  .cupones-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .cupon-item {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 250px;
    text-align: center;
  }
  
  .cupon-thumbnail {
    max-width: 100%;
    border-radius: 8px;
  }
  
  .cupon-details {
    margin-top: 10px;
  }
  
  .cupon-details p {
    margin: 5px 0;
  }
  
  .cupon-details button {
    background-color: #6a1b9a;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .cupon-details button:hover {
    background-color: #8e24aa;
  }
  
  @media (max-width: 768px) {
    .cupones-list {
      flex-direction: column;
      align-items: center;
    }
  
    .cupon-item {
      width: 100%;
    }
  }
  