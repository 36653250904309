/* styles.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f3f3f3;
  color: #333;
  margin: 0;
  padding: 0;
}

header {
  background-color: #6a1b9a;
  color: white;
  padding: 10px 20px;
  text-align: center;
}

h1 {
  color: #6a1b9a;
}

button {
  background-color: #6a1b9a;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #8e24aa;
}

.cupon {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin: 15px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cupon img {
  max-width: 100%;
  border-radius: 8px;
}

.cupon a {
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  color: #6a1b9a;
  font-weight: bold;
  text-align: center;
}

.cupon a:hover {
  color: #8e24aa;
}

.cupon-image-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cupon-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #6a1b9a;
  background: transparent;
  border-radius: 50%;
  padding: 5px;
  font-size: 24px; /* Tamaño del icono más grande */
}

button.active {
  background-color: #8e24aa;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .cupon {
    flex-direction: column; /* Asegura que el contenido del cupón esté en columna */
    justify-content: center; /* Asegura que el contenido esté centrado verticalmente */
  }
}
